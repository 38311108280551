var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "List Move Location" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.form
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.branch.label
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value:
                                                _vm.formRules.branch.decorator,
                                              expression:
                                                "formRules.branch.decorator"
                                            }
                                          ],
                                          attrs: {
                                            name: _vm.formRules.branch.name,
                                            placeholder: _vm.$t(
                                              _vm.formRules.branch.placeholder
                                            ),
                                            showSearch: true,
                                            "option-filter-prop": "children",
                                            "filter-option": _vm.filterOption,
                                            loading: _vm.loading.loadingBranch,
                                            allowClear: true
                                          },
                                          on: {
                                            search: function(value) {
                                              return _vm.getBranch(value)
                                            },
                                            change: _vm.handleChangeBranch
                                          }
                                        },
                                        _vm._l(_vm.dataBranch, function(
                                          data,
                                          index
                                        ) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: data.id }
                                            },
                                            [
                                              _c(
                                                "a-tooltip",
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "title" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(data.name) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.name) +
                                                      " "
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.customerName.label
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value:
                                                _vm.formRules.customerName
                                                  .decorator,
                                              expression:
                                                "formRules.customerName.decorator"
                                            }
                                          ],
                                          attrs: {
                                            name:
                                              _vm.formRules.customerName.name,
                                            placeholder: _vm.$t(
                                              _vm.formRules.customerName
                                                .placeholder
                                            ),
                                            showSearch: true,
                                            "option-filter-prop": "children",
                                            "filter-option": _vm.filterOption,
                                            loading:
                                              _vm.loading.loadingCustomerName,
                                            allowClear: true
                                          },
                                          on: {
                                            search: function(value) {
                                              return _vm.getCustomerName(value)
                                            },
                                            change: _vm.handleChangeCustomer
                                          }
                                        },
                                        _vm._l(_vm.dataCustomerName, function(
                                          data,
                                          index
                                        ) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: data.id }
                                            },
                                            [
                                              _c(
                                                "a-tooltip",
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "title" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "" +
                                                              data.fullName.replace(
                                                                "null",
                                                                ""
                                                              )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        "" +
                                                          data.fullName.replace(
                                                            "null",
                                                            ""
                                                          )
                                                      ) +
                                                      " "
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.internalContractNo.label
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value:
                                                _vm.formRules.internalContractNo
                                                  .decorator,
                                              expression:
                                                "formRules.internalContractNo.decorator"
                                            }
                                          ],
                                          attrs: {
                                            name:
                                              _vm.formRules.internalContractNo
                                                .name,
                                            placeholder: _vm.$t(
                                              _vm.formRules.internalContractNo
                                                .placeholder
                                            ),
                                            showSearch: true,
                                            "option-filter-prop": "children",
                                            "filter-option": _vm.filterOption,
                                            loading:
                                              _vm.loading
                                                .loadingInternalContract,
                                            allowClear: true
                                          },
                                          on: {
                                            search: function(value) {
                                              return _vm.getInternalContract(
                                                value
                                              )
                                            },
                                            change: _vm.handleChangeIC
                                          }
                                        },
                                        _vm._l(
                                          _vm.dataInternalContract,
                                          function(data, index) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: index,
                                                attrs: { value: data.icNumber }
                                              },
                                              [
                                                _c(
                                                  "a-tooltip",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "title" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.icNumber
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(data.icNumber) +
                                                        " "
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.createdDate.label
                                        )
                                      }
                                    },
                                    [
                                      _c("a-range-picker", {
                                        attrs: {
                                          format: _vm.DEFAULT_DATE_FORMAT,
                                          placeholder: [
                                            _vm.$t("lbl_start_date"),
                                            _vm.$t("lbl_end_date")
                                          ]
                                        },
                                        model: {
                                          value: _vm.filterForm.date,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filterForm,
                                              "date",
                                              $$v
                                            )
                                          },
                                          expression: "filterForm.date"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            loading: _vm.loading.loadingFind
                                          },
                                          on: { click: _vm.onSubmit }
                                        },
                                        [_vm._v(" Find ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c("TableCustom", {
                                attrs: {
                                  dataSource: _vm.dataSource,
                                  columns: _vm.columnsTable,
                                  paginationcustom: true,
                                  defaultPagination: false,
                                  scroll: { x: "calc(700px + 50%)", y: 400 }
                                },
                                on: { "on-view": _vm.responseViewTable }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c("Pagination", {
                                attrs: {
                                  total: _vm.totalData,
                                  pageSizeSet: _vm.limit,
                                  idPagination: "pagination1"
                                },
                                on: {
                                  "response-pagesize-change":
                                    _vm.responsePageSizeChange,
                                  "response-currentpage-change":
                                    _vm.responseCurrentPageChange
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 12, align: "end" } },
                            [
                              _c(
                                "a-tag",
                                {
                                  staticStyle: { "font-size": "13px" },
                                  attrs: { color: "grey" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_total_found")) +
                                      " : " +
                                      _vm._s(_vm.totalData) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }